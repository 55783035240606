<template>
  <div class="card card-body">
    <div class="row table-component">
      <div class="col-xxl-12 col-md-12">
        <div class="align-middle flex justify-between line-header">
          <div class="fs-16 table-header-title flex align-center justify-center">
            {{ $t('playDiscs.ads-setting-info') }}
          </div>
          <div v-if="!isCreate">
            <b-button variant="primary" :disabled="loadingAction" @click="onSubmit"
                      class="waves-effect waves-light">
              <div class="flex">
                <span v-if="loadingAction" class="d-flex align-items-center mr-2">
                  <Loading/>
                </span>
                {{ $t("t-renew") }}
              </div>
            </b-button>
          </div>
        </div>
      </div>
      <div class="col-xxl-12 col-md-12 mt-3">
        <label class="w-100">
          広告プレイリスト設定
        </label>

        <div class="d-flex justify-content-around" v-if="$props.facilityId">
          <div>
            <input
              class="form-check-input fs-14 mt-0 align-middle me-1"
              name="playlist-radios"
              type="radio"
              v-model="state.playlistType"
              value="facility"
            />
            施設全体に広告を設定
          </div>

          <div>
            <input
              class="form-check-input fs-14 mt-0 align-middle me-1"
              name="playlist-radios"
              type="radio"
              v-model="state.playlistType"
              value="room"
            />
            サウナ室毎に広告を設定
          </div>

        </div>

        <div class="mt-2">
          <div class="mt-2 mb-1">
            <p :class="['mb-1', state.playlistType === 'room'? state.disabledText:'']">施設全体</p>
            <Multiselect
              v-model="state.formData.playlistId"
              :placeholder="'プレイリストを選択してください'"
              class="multiselect-input"
              style="padding: 0;"
              :close-on-select="true"
              :searchable="true"
              :track-by="'name'"
              :label="'name'"
              :options="state.optionsPlayList"
              :multiple="false"
              :disabled="state.playlistType === 'room'"
            >
              <template #noresults>
                <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
              </template>
              <template #nooptions>
                <div class="d-flex justify-content-center m-2">{{ $t('t-list-empty') }}</div>
              </template>
            </Multiselect>
          </div>

          <div class="mt-3">
            <div v-for="(item) in state.rooms">
              <p :class="['mb-1', state.playlistType === 'facility'? state.disabledText:'']">{{ ROOM_TYPE[item.type] }}
                -
                {{ item.user?.tabletId }}</p>
              <Multiselect
                v-model="item.playlistId"
                :placeholder="'プレイリストを選択してください'"
                class="multiselect-input"
                style="padding: 0;"
                :close-on-select="true"
                :searchable="true"
                :track-by="'name'"
                :label="'name'"
                :options="state.optionsPlayList"
                :multiple="false"
                :disabled="state.playlistType === 'facility'"
              >
                <template #noresults>
                  <div class="d-flex justify-content-center m-2">{{ $t('t-no-data') }}</div>
                </template>
                <template #nooptions>
                  <div class="d-flex justify-content-center m-2">{{ $t('t-list-empty') }}</div>
                </template>
              </Multiselect>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref, watch} from "vue";
import Loading from "@/components/Loading";
import i18n from '@/i18n'
import Multiselect from "@vueform/multiselect";
import store from "@/state/store";
import {adsService, facilityService, roomService} from "@/services";
import {ROOM_TYPE} from "@/constants";
import {isEmpty} from "lodash";

export default {
  name: "SettingAds",
  computed: {
    ROOM_TYPE() {
      return ROOM_TYPE
    }
  },

  components: {
    Loading,
    Multiselect
  },
  props: {
    isCreate: {
      type: Boolean,
      default: false
    },
    facilityId: {
      type: String,
      default: ''
    },
    playlistId: {
      type: String,
      default: ''
    },
    playlistType: {
      type: String,
      default: 'facility'
    },
    stateUpdateRoom: {
      type: Number,
      default: 0
    },
  },
  setup(props, {emit}) {
    const state = reactive({
      formData: {
        playlistId: ''
      },
      optionsPlayList: [],
      rooms: [],
      playlistType: '',
      disabledText: ref('disabled-text'),
    })

    onMounted(() => {
      getOptionsPlaylist();
      state.playlistType = props.playlistType;
    })

    watch(() => props.stateUpdateRoom, async () => {
      await resetComponent()
    })

    watch(() => props.playlistId, (value) => {
      if (value === '') return;
      state.formData.playlistId = value;
    })

    watch(() => props.playlistType, (value) => {
      state.playlistType = value;
    })

    const loadingAction = ref(false);
    const onSubmit = async () => {
      if (props.isCreate) {
        emit('createSettingAds', state.formData.playlistId);
        return;
      }

      if (props.facilityId !== '') {
        try {
          // handle update Ads
          loadingAction.value = true;
          const payload = {
            playlistId: state.playlistType === 'facility' ? state.formData.playlistId : null,
            playlistType: state.playlistType,
            playlistRoom: state.playlistType === 'room' ? state.rooms?.map(e => {
                return {
                  playlistId: e.playlistId,
                  roomId: e.id
                }
              }
            ) : null
          }
          await facilityService.updateAdsForFacility(props.facilityId, payload)
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'success',
            message: i18n.global.t('msg.saved')
          });
        } catch (e) {
          store.commit('settings/SET_POPUP', {
            show: true,
            type: 'error',
            message: e.message
          });
        } finally {
          loadingAction.value = false;
          await resetComponent()
        }
      }
    }

    const getOptionsPlaylist = async () => {
      try {
        if (!isEmpty(props.facilityId))
          state.rooms = await roomService.getRoomHasSensor(props.facilityId)
        state.optionsPlayList = await adsService.getPlaylistOptions();
      } catch (e) {
        console.log(e, 'error get play list options')
      }
    }

    const resetComponent = async () => {
      try {
        await getOptionsPlaylist();
        const facility = await facilityService.getFacility(props.facilityId);
        if (facility.playlistType === 'facility') {
          state.formData.playlistId = facility.playlistId
          state.playlistType = 'facility'
        } else {
          state.formData.playlistId = null
          state.playlistType = 'room'
        }
      } catch (e) {
        console.log(e, 'error get play list options')
      }
    }


    return {
      state,
      loadingAction,
      onSubmit
    }
  }
}
</script>

<style scoped lang="scss">
.multiselect {
  font-size: 13px !important;
}

.multiselect-input ::v-deep {
  .multiselect-wrapper {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) + var(--ms-py, .5rem) * 2 - 2px);
    outline: none;
    position: relative;
    width: 100%;
  }

}

.line-header {
  border-bottom: 1px solid var(--vz-border-color) !important;
  padding-top: 10px;
  padding-bottom: 20px;
}

.is-disabled {
  background: var(--ms-bg-disabled, #f3f4f6) !important;
  color: var(--ms-bg-disabled, #bbbbbb) !important;
  cursor: default;
}

.disabled-text {
  color: var(--ms-bg-disabled, #bbbbbb) !important;
}


</style>
