<template>
  <Layout>
    <PageHeader :title=title :items="itemHeader"></PageHeader>
    <div class="mb-3 d-flex justify-content-end">
      <b-button variant="primary" @click="addFacility" :disabled="state.loadingAction" class="waves-effect waves-light">
        <div class="flex">
                <span v-if="state.loadingAction" class="d-flex align-items-center mr-2">
                  <Loading/>
                </span>
          {{ $t("t-register-btn") }}
        </div>
      </b-button>
    </div>
    <div class="row">
      <div class="col-xxl-9 col-md-9">
        <div class="row">
          <div class="col-xxl-12 col-md-12">
            <facility-information
                @reload="loadFacility"
                @createFacility="setParamFacility"
                :facility="state.facility"
                :is-create="true"
                :facility-id-str="state.facilityIdStr"
                ref="facility-information"
            />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room
                room-sub-type="SAUNA_DRIED"
                :facility="state.facility"
                :is-create="true"
                @createRoom="setParamRoom"
                ref="room_SAUNA_DRIED"
            />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_WET"
                  :facility="state.facility"
                  :is-create="true"
                  @createRoom="setParamRoom"
                  ref="room_SAUNA_WET"
            />
          </div>

          <div class="col-xxl-12 col-md-12">
            <Room room-sub-type="SAUNA_RESTED"
                  :facility="state.facility"
                  :is-create="true"
                  @createRoom="setParamRoom"
                  ref="room_SAUNA_RESTED"
            />
          </div>
        </div>
      </div>
      <div class="col-xxl-3 col-md-3">
        <div class="row">
          <div class="col-xxl-12 col-md-12">
            <FacilityFilterInformation
                :facility="state.facility"
                :is-create="true"
                @createService="setParamService"
                ref="facility_filter_information"
            />
          </div>
          <div class="col-xxl-12 col-md-12">
            <Settings
              :is-create="true"
              ref="facility_settings"
            />
          </div>
          <div class="col-xxl-12 col-md-12">
            <SettingAds
                @createSettingAds="setParamSettingAds"
                :is-create="true"
                ref="facility_setting_ads"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main.vue'
import PageHeader from "@/components/page-header.vue";
import {reactive, ref} from "vue";
import i18n from "@/i18n";
import FacilityInformation from "@/views/setting/facility-management/components/FacilityInformation.vue";
import Room from "@/views/setting/facility-management/components/Room.vue"
import Settings from "@/views/setting/facility-management/components/Settings.vue";
import SettingAds from "@/views/setting/facility-management/components/SettingAds.vue";
import FacilityFilterInformation from "@/views/setting/facility-management/components/FacilityFilterInformation.vue";
import Loading from "@/components/Loading";
import {concat, get} from "lodash";
import {facilityService} from "@/services";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
    FacilityInformation,
    Room,
    Settings,
    SettingAds,
    FacilityFilterInformation,
    Loading
  },
  setup() {
    const loadingAction = ref(false);
    const title = ref(i18n.global.t('facility.facility-details'));
    const itemHeader = reactive([
      {
        text: i18n.global.t('facility.facility-information'),
        href: "/admin/facility/list",
      },
      {
        text: i18n.global.t('facility.facility-details'),
        active: true,
      },
    ]);

    const state = reactive({
      facility: {},
      formPayloadCreateFacility: {
        rooms: [],
        facilityServices: [],
        playlistId: ''
      },
      loadingAction: false,
      validate: {
        formFacility: false,
        SAUNA_DRIED: true,
        SAUNA_WET: true,
        SAUNA_RESTED: true
      },
      facilityIdStr: ''
    })

    const loadFacility = () => {
      console.log('loadFacility')
    }

    const setParamFacility = async (formFacility) => {
      state.validate.formFacility = formFacility.validate;

      if (formFacility.validate) {
        state.formPayloadCreateFacility = {...state.formPayloadCreateFacility, ...formFacility.payload};
      }
    }
    const setParamRoom = (formRoom) => {
      if (formRoom.validate) {
        state.formPayloadCreateFacility.rooms = concat(...state.formPayloadCreateFacility.rooms, ...formRoom.payload);
      }

      state.validate[formRoom.type] = formRoom.validate;
    }
    const setParamService = (formService) => {
      state.formPayloadCreateFacility.facilityServices = formService;
    }

    const setParamSettingAds = (value) => {
      state.formPayloadCreateFacility.playlistId = value
    }

    const refreshPayload = () => {
      state.formPayloadCreateFacility = {
        rooms: [],
        facilityServices: []
      }
    }

    const updateStateFacility = (facilityIdStr) => {
      state.facilityIdStr = facilityIdStr
    }

    const handleChangeLoading = (value) => {
      state.loadingAction = value
    }

    return {
      title,
      itemHeader,
      state,
      loadingAction,
      loadFacility,
      setParamFacility,
      setParamRoom,
      setParamService,
      refreshPayload,
      updateStateFacility,
      handleChangeLoading,
      setParamSettingAds
    }
  },

  methods: {
    async addFacility() {
      this.handleChangeLoading(true);
      this.refreshPayload()
      this.$refs['facility-information'].onSubmit();
      this.$refs['room_SAUNA_DRIED'].onSubmit();
      this.$refs['room_SAUNA_WET'].onSubmit();
      this.$refs['room_SAUNA_RESTED'].onSubmit();
      this.$refs['facility_filter_information'].onSubmit();
      this.$refs['facility_settings'].onSubmit();
      this.$refs['facility_setting_ads'].onSubmit();

      setTimeout( async () => {
        try {
          if (Object.values(this.state.validate).some(item => !item)) {
            return;
          }
          const res = await facilityService.createFacility(this.state.formPayloadCreateFacility);
          this.updateStateFacility(res.facilityIdStr);
          await this.$router.push({name: 'admin-facility-list'});

          this.$store.commit('settings/SET_POPUP', {
            show: true,
            type: 'success',
            message: i18n.global.t('msg.saved')
          });
        } catch (e) {
          const message = get(e, 'response.data.message', '');
          await Swal.fire({
            title: "Create failed",
            text: message,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        } finally {
          this.handleChangeLoading(false)
        }
      }, 1000)

    }
  }
}
</script>
